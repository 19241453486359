* {
  --white: #ffffff !important;
  --primary-color: #c1db62 !important;
  --secondary-color: #36aa87 !important;
  --thirty-color: #f5f5f5 !important;
  --fourth-color: #d8d8d8 !important;
  font-family: "Nunito", sans-serif;
  box-sizing: border-box;
}

.green-important {
  color: var(--green) !important;
}

.flex-column {
  flex-direction: column;
}

@media screen and (min-width: 1200px) {
  .Dashboard-content-fixed {
    display: flex !important;
    flex-direction: column !important;
  }

  .xl-h-100 {
    height: 100%;
  }
}

.equipment-container {
  display: flex;
  flex-wrap: wrap;
}

.equipment-item {
  display: inline-flex;
  font-size: 14px;
  background-color: transparent;
  color: var(--black);
  margin: 0.2rem;
  padding: 0.4rem;
  border-radius: 10px;
  border: solid 1px var(--black);
  transition: 0.3s;
}

.equipment-item.edit-field {
  cursor: pointer;
}

.equipment-item > div {
  margin: auto;
  margin-left: 0.4rem;
}

.equipment-item.select {
  color: var(--white);
  background-color: var(--primary-color);
  border: solid 1px var(--primary-color);
}

.equipment-item.edit-field:hover {
  opacity: 0.7;
}

.equipment-item.edit-field:active {
  opacity: 0.5;
}

.br-15 {
  border-radius: 15px;
}

.grid-line {
  display: flex;
  padding: 20px;
  position: absolute;
  top: -64px;
  left: -12px;
  bottom: -20px;
  right: 0;
  background-color: transparent;
  background-image: linear-gradient(
      0deg,
      transparent 84%,
      rgba(20, 20, 20, 0.05) 45%,
      rgba(20, 20, 20, 0.05) 46%,
      transparent 47%,
      transparent 94%,
      rgba(20, 20, 20, 0.05) 95%,
      rgba(20, 20, 20, 0.05) 96%,
      transparent 97%,
      transparent
    ),
    linear-gradient(
      90deg,
      transparent 84%,
      rgba(20, 20, 20, 0.05) 45%,
      rgba(20, 20, 20, 0.05) 46%,
      transparent 47%,
      transparent 94%,
      rgba(20, 20, 20, 0.05) 95%,
      rgba(20, 20, 20, 0.05) 96%,
      transparent 97%,
      transparent
    );
  background-size: 50px 50px;
}

.bg-vehicle {
  position: absolute;
  top: -64px;
  right: -12px;
  bottom: -20px;
  left: -20px;
  background-color: #fafafa;
  padding: 20px;
}

.radius-10 {
  border-radius: 10px;
}

.radius-l-10 {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.radius-r-10 {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.bg-primary {
  background-color: var(--primary-color) !important;
}

/* start descriptive state table */

.DescriptiveState-table {
  border-collapse: collapse;
  width: 100%;
}

.DescriptiveState-table tr.space {
  height: 20px;
}

.DescriptiveState-table th,
.DescriptiveState-table td {
  font-size: 16px;
  padding: 6px 10px;
  font-weight: normal;
  text-align: center;
  color: var(--primary-color);
  background-color: var(--white);
  overflow: hidden;
}

.DescriptiveState-table th:first-child,
.DescriptiveState-table td:first-child {
  text-align: left;
}

.DescriptiveState-table tr:first-child th:first-child,
.DescriptiveState-table tr:first-child td:first-child {
  border-top-left-radius: 10px;
}

.DescriptiveState-table tr:last-child th:first-child,
.DescriptiveState-table tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.DescriptiveState-table tr > th:last-child,
.DescriptiveState-table tr > td:last-child {
  text-align: right;
}

.DescriptiveState-table tr:first-child th:last-child,
.DescriptiveState-table tr:first-child td:last-child {
  border-top-right-radius: 10px;
}

.DescriptiveState-table tr:last-child th:last-child,
.DescriptiveState-table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.DescriptiveState-table th {
  background-color: var(--primary-color);
  color: var(--white);
}

@media only screen and (max-width: 680px) {
  .DescriptiveState-table th,
  .DescriptiveState-table td {
    font-size: 13px;
  }
}

/* end descriptive state table */

/* start descriptive state parts */

.parts-container {
  display: flex;
}

.parts-title,
.parts-status,
.parts-value,
.parts-picture {
  display: flex;
  width: 25%;
  height: 50px;
  background-color: var(--primary-color);
  color: var(--white);
  border: solid 2px var(--primary-color);
  margin: 4px;
  font-size: 14px;
}

.parts-title {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 12px;
}

.parts-title > span,
.parts-title > div {
  margin-top: auto;
  margin-bottom: auto;
}

.parts-title > span {
  margin-right: auto;
}

.parts-title > div:last-child {
  display: none;
  padding-left: 12px;
}

.parts-status {
  background-color: transparent;
}

.parts-status > select,
.parts-status > input,
.parts-title > input {
  width: 100%;
  border: none;
  margin: 0;
  height: 100%;
  padding: 12px;
  color: var(--primary-color);
  border-radius: 0;
  background-color: transparent;
}

.parts-title > input {
  color: var(--white);
}

.parts-status > select:focus,
.parts-status > input:focus,
.parts-title > input:focus {
  outline: none;
}

.parts-value {
  background-color: transparent;
  color: var(--black);
}

.parts-value.red {
  background-color: var(--red-light);
  color: var(--black);
}

.parts-value.green {
  background-color: var(--green-light);
  color: var(--black);
}

.parts-value > button {
  background-color: var(--primary-color);
  color: var(--white);
  width: 45px;
  height: 100%;
  margin: 0;
  border: none;
  cursor: pointer;
  font-size: 22px;
  transition: 0.3s;
}

.parts-value > button:hover {
  opacity: 0.7;
}

.parts-value > button:active {
  opacity: 0.5;
}

.parts-picture {
  background-color: transparent;
  border-style: dashed;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.parts-picture > div {
  color: var(--primary-color);
  font-size: 22px;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .parts-container {
    flex-direction: column;
    margin-bottom: 12px;
  }

  .parts-title,
  .parts-status,
  .parts-value,
  .parts-picture {
    display: flex;
    width: 100%;
    border-radius: 0px;
    margin: 2px;
  }

  .parts-title > div:last-child {
    display: inline-block;
  }

  .parts-title {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .parts-picture {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

/* end descriptive state parts */

/* start SVG */

.svg-hover {
  cursor: pointer;
  transition: 0.3s;
}

.svg-active {
  fill: var(--secondary-color);
}

.svg-hover:hover {
  fill: var(--gray);
}

/* end SVG */

/* start administrative */

.select-administrative,
.input-administrative {
  border: none;
  height: 100%;
  width: 100%;
  background-color: transparent;
}

.select-administrative:focus,
.input-administrative:focus,
.value-administrative > button {
  outline: none;
}

.value-administrative {
  display: flex;
  width: 100%;
  height: 100%;
}

.value-administrative > button {
  background-color: var(--gray);
  border: none;
  height: 100%;
  margin: -6px -10px;
  padding: 12px 0;
  width: 48px;
  cursor: pointer;
}

.value-administrative > span {
  display: inline-block;
  margin: auto;
  width: 100%;
  height: 100%;
  text-align: center;
}

/* end administrative */

.rotate {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.col_full_height {
  display: flex;
  justify-content: center;
  flex-direction: column;
}